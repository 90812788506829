<template>
  <div class="d-inline-block">
    <b-button
      class="list-button"
      variant="outline-primary"
      size="sm"
      @click="getActivityLogs"
      title="show history"
    >
      <b-icon icon="clock-history" class="list-button" />
    </b-button>
    <!--    <b-icon-->
    <!--      icon="clock-history"-->
    <!--      class="mt-1 clickable float-right"-->
    <!--      variant="secondary"-->
    <!--      style="font-size: 1.2em"-->
    <!--      @click="getActivityLogs"-->
    <!--    />-->
    <b-modal v-model="modalList" size="lg" centered scrollable hide-footer>
      <b-table-lite
        v-if="list.length"
        :items="items"
        :fields="fields"
        bordered
        striped
      >
        <template #cell(actionDatetime)="row">
          {{ row.value | datetimelong }}
        </template>
        <template #cell(showDetails)="row">
          <b-button size="sm" @click="row.toggleDetails" class="mr-2">
            {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>
        </template>
        <template #row-details="row">
          <div><small class="text-muted pl-2">added:</small></div>
          <div>
            <small
              class="d-block text-success pl-3"
              v-for="answer in row.item.intentAnswers.added"
              :key="JSON.stringify(answer)"
            >
              <json-viewer :value="answer" />
            </small>
          </div>
          <div><small class="text-muted pl-2">removed:</small></div>
          <div>
            <small
              class="d-block text-warning pl-3"
              v-for="answer in row.item.intentAnswers.removed"
              :key="JSON.stringify(answer)"
            >
              <json-viewer :value="answer" />
            </small>
          </div>
        </template>
      </b-table-lite>
      <span v-else>There is no recent history</span>
    </b-modal>
  </div>
</template>

<script>
import HttpHandler from '@/mixins/HttpHandler';
import _ from 'lodash';
import JsonViewer from '@/components/UI/JsonViewer';

export default {
  name: 'ChatRatingActivityLog',
  props: {
    botId: Number,
  },
  components: { JsonViewer },
  data() {
    return {
      list: [],
      modalList: false,
      fields: [
        {
          key: 'actionDatetime',
          label: 'Datetime',
        },
        {
          key: 'userName',
          label: 'Username',
        },
        {
          key: 'action',
          label: 'Action',
        },
        {
          key: 'showDetails',
          label: '',
        },
      ],
    };
  },
  computed: {
    items() {
      let list = _.map(this.list, (log) => {
        let logObj = _.pick(log, [
          'actionDatetime',
          'userName',
          'action',
          'data',
        ]);
        const dataObj = JSON.parse(logObj.data);

        let oldObj = { ...dataObj.Old };
        let newObj = { ...dataObj.New };

        logObj.intentAnswers = {
          removed: [],
          added: [],
        };

        let oldAnswer = {};
        let newAnswer = {};

        if (logObj.action === 'Edit' || logObj.action === 'Delete') {
          oldAnswer = {
            ...JSON.parse([...oldObj.IntentAnswers][0].Answer).content,
            ...{
              fields: JSON.parse([...oldObj.IntentAnswers][1].Answer).content
                .fields,
            },
          };
        }

        if (logObj.action === 'Edit' || logObj.action === 'Add') {
          newAnswer = {
            ...JSON.parse([...newObj.IntentAnswers][0].Answer).content,
            ...{
              fields: JSON.parse([...newObj.IntentAnswers][1].Answer).content
                .fields,
            },
          };
        }

        logObj.intentAnswers.removed.push(oldAnswer);
        logObj.intentAnswers.added.push(newAnswer);

        logObj.action = logObj.action === 'Add' ? 'Enable' : logObj.action;
        logObj.action = logObj.action === 'Delete' ? 'Disable' : logObj.action;

        return logObj;
      });
      return list;
    },
  },
  mixins: [HttpHandler],
  methods: {
    async getActivityLogs() {
      const url = encodeURI(
        `/api/bots/${this.botId}/chat-rating/activity-logs`
      );
      let resp = await this.httpclient.get(url);
      this.list = resp.data;
      this.modalList = true;
    },
  },
};
</script>

<style scoped>
.list-button {
  padding: 1px 3px;
}

.list-button:hover {
  color: #fff;
}

/*.list-button:hover {*/
/*  border: 1px solid #00b6f0;*/
/*  border-radius: 2px;*/
/*  padding: 2px;*/
/*}*/
</style>
