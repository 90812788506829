<template>
  <div ref="container">
    <div class="form-group row" v-for="(option, index) in options" :key="index">
      <div v-if="index === 0" class="col-sm-3">
        <label>Option</label>
      </div>
      <div class="col-sm-8" :class="{ 'offset-3': index > 0 }">
        <b-input
          class="form-control-sm"
          v-model.trim="options[index]"
          @change="$sanitize('options.' + index)"
          required
        ></b-input>
      </div>
      <div class="col-sm-1">
        <span class="btn-inline-text" @click="removeOption(option)">
          <b-icon-x />
        </span>
      </div>
    </div>
    <div class="col-9 offset-3">
      <span class="btn-inline-text" @click="options.push('')">
        <b-icon-plus style="font-size: 1.2em" /> Add option
      </span>
    </div>
  </div>
</template>

<script>
export default {
  /*global _*/
  props: ['optionArray'],
  mounted() {
    this.options = this.optionArray;
  },
  data() {
    return {
      options: [''],
    };
  },
  methods: {
    removeOption(option) {
      this.options = _.filter(this.options, (a) => {
        return a !== option;
      });
      //add an empty option if it becomes empty
      if (this.options.length < 1) {
        this.options.push('');
      }
      this.emitUpdate();
    },
    updateOption(option, index) {
      option = this.$sanitize('options.' + index);

      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit('update:optionArray', this.options);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-inline-text {
  font-size: 0.9rem;

  &:hover {
    cursor: pointer;
    font-weight: bolder;
  }
}
</style>
