<template>
  <div>
    <b-row>
      <b-col class="col-lg-12">
        <h1 class="d-inline mr-3">Chat Rating</h1>
        <chat-rating-activity-log class="align-top pt-3" :botId="botId" />
      </b-col>
      <b-col class="col-lg-3 mt-sm-3">
        <bot-selector @botChanged="refreshData" />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-sm-3">
        <b-form-checkbox v-model="isEnabled"> Enabled?</b-form-checkbox>
      </b-col>
    </b-row>

    <b-row :class="{ collapse: !isEnabled }" style="">
      <b-col class="col-lg-8 mt-3">
        <b-form ref="main-form">
          <b-form-group label="Greeting Message">
            <answer-text :answer-text.sync="answer"></answer-text>
          </b-form-group>
          <b-form-group label="Fields">
            <answer-form :form-fields.sync="formFields"></answer-form>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-lg-8">
        <b-button
          class="float-right"
          variant="primary"
          @click.prevent="submitForm"
          >Submit
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AnswerText from '@/components/Intents/AnswerTypeText';
import AnswerForm from '@/components/Intents/AnswerForm';
import httpclient from '@/mixins/HttpHandler';
import BotSelector from '@/components/UI/BotSelector';
import ChatRatingActivityLog from '@/components/Bots/ChatRatingActivityLog.vue';

export default {
  /*global _*/
  mixins: [httpclient],
  components: {
    AnswerText,
    AnswerForm,
    BotSelector,
    ChatRatingActivityLog,
  },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    this.refreshData(botId);
  },
  data() {
    return {
      path: 'chat-rating',
      botId: 1,
      bots: [],
      isEnabled: true,
      config: {},
      showConfirm: true,
      showConfirmSettings: {
        message: '',
      },
      answerText: {},
      answerTextDefault: {
        answer: JSON.stringify({
          type: 'text',
          content: {
            message: 'Please rate you chat experience: ',
            links: [],
          },
        }),
        weight: 1,
        answerType: 1,
      },
      formFields: [
        {
          name: 'Rating',
          isRequired: true,
          type: 'rating',
          scales: [
            { name: 'Scale 5', text: 'Excellent', isVisible: true, value: 5 },
            { name: 'Scale 4', text: 'Very Good', isVisible: true, value: 4 },
            { name: 'Scale 3', text: 'Good', isVisible: true, value: 3 },
            { name: 'Scale 2', text: 'Fair', isVisible: true, value: 2 },
            { name: 'Scale 1', text: 'Poor', isVisible: true, value: 1 },
          ],
          compulsory: true,
        },
        {
          name: 'Comment',
          isRequired: true,
          type: 'textArea',
          compulsory: true,
        },
      ],
      formFieldsDefault: [
        {
          name: 'Rating',
          isRequired: true,
          type: 'rating',
          scales: [
            { name: 'Scale 5', text: 'Excellent', isVisible: true, value: 5 },
            { name: 'Scale 4', text: 'Very Good', isVisible: true, value: 4 },
            { name: 'Scale 3', text: 'Good', isVisible: true, value: 3 },
            { name: 'Scale 2', text: 'Fair', isVisible: true, value: 2 },
            { name: 'Scale 1', text: 'Poor', isVisible: true, value: 1 },
          ],
          compulsory: true,
        },
        {
          name: 'Comment',
          isRequired: true,
          type: 'textArea',
          compulsory: true,
        },
      ],
    };
  },
  computed: {
    answer: {
      get() {
        if (this.answerText && this.answerText.answer) {
          return JSON.parse(this.answerText.answer);
        }

        return {
          type: 'text',
          content: {
            message: 'Please rate you chat experience: ',
            links: [],
          },
        };
      },
      set(val) {
        this.answerText.answer = JSON.stringify(val);
      },
    },
  },
  watch: {
    isEnabled: function () {
      // eslint-disable-next-line no-console
      console.log(this.answerText);
    },
  },
  methods: {
    async refreshData(botId) {
      if (botId) this.botId = botId;

      let resp = await this.httpclient.get(`api/bots/${botId}/chat-rating`);
      resp = resp.data;

      if (resp.data.length > 0) {
        this.isEnabled = true;
        const answers = resp.data;
        this.answerText = _.filter(answers, { answerType: 1 })[0];
        const answerForm = _.filter(answers, { answerType: 2 })[0];
        const formFields = JSON.parse(answerForm.answer).content.fields;
        if (formFields) {
          this.formFields = formFields;
        }
      } else {
        this.isEnabled = false;
        this.answerText = _.cloneDeep(this.answerTextDefault);
        this.formFields = _.cloneDeep(this.formFieldsDefault);
      }
    },
    async submitForm() {
      const confirm = await this.$bvModal.msgBoxConfirm(
        'Update the chat rating message?'
      );

      if (confirm) {
        try {
          if (this.isEnabled) {
            await this.enableChatRating();
          } else {
            await this.disableChatRating();
          }

          await this.$bvModal.msgBoxOk(
            'Chat rating had been updated successfully',
            { okVariant: 'success' }
          );
        } catch {
          await this.$bvModal.msgBoxOk('Failed. Please try again.', {
            okVariant: 'danger',
          });
        }
      }
    },
    enableChatRating() {
      const mainForm = this.$refs['main-form'];
      if (!mainForm.reportValidity()) {
        return;
      }

      let answers = [];
      answers.push(this.answerText);
      answers.push({
        answer: JSON.stringify({
          type: 'form',
          content: {
            text: '',
            message: '',
            ifNeedConfirm: true,
            fields: this.formFields,
          },
        }),
        weight: 1,
        answerType: 2,
      });

      const systemMessage = {
        botId: this.botId,
        systemMessageType: 'chatrating',
        intentAnswers: answers,
      };

      return this.httpclient.post(
        `api/bots/${this.botId}/chat-rating`,
        systemMessage
      );
    },
    disableChatRating() {
      this.answerText = _.cloneDeep(this.answerTextDefault);
      this.formFields = _.cloneDeep(this.formFieldsDefault);

      return this.httpclient.delete(`api/bots/${this.botId}/chat-rating`);
    },
  },
};
</script>

<style scoped></style>
