<template>
  <div ref="container">
    <div>
      <b-table striped hover small :items="tableItems" :fields="tableHeaders">
        <template v-slot:cell(isRequired)="data">
          {{ data.value ? 'Yes' : 'No' }}
        </template>
        <template v-slot:cell(type)="data">
          {{ getTypeName(data.value) }}
        </template>
        <template v-slot:cell(action)="data">
          <b-icon-pencil-square
            class="clickable"
            title="Edit field"
            @click="showModalForEdit(data)"
          />
          <b-icon-x
            v-if="!data.item.compulsory"
            class="clickable"
            title="Remove field"
            @click="removeFormField(data.item)"
          ></b-icon-x>
        </template>
      </b-table>
    </div>
    <div>
      <span class="btn-inline-text" @click="showModal">
        <b-icon-plus style="font-size: 1.2em" /> Add Field
      </span>
    </div>

    <b-modal ref="field-modal" :title="isEdit ? 'Edit Field' : 'Add Field'">
      <b-form ref="field-form">
        <div class="form-group row">
          <div class="col-sm-3">
            <label>Name</label>
          </div>
          <div class="col-sm-9">
            <b-input
              class="form-control-sm"
              v-model.trim="formField.name"
              required
              @change="$sanitize('formField.name')"
            ></b-input>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label>Required?</label>
          </div>
          <div class="col-sm-9">
            <b-form-checkbox v-model="formField.isRequired" />
          </div>
        </div>
        <div v-if="!formField.compulsory" class="form-group row">
          <div class="col-sm-3">
            <label>Type</label>
          </div>
          <div class="col-sm-9">
            <b-form-select
              v-model="formField.type"
              :options="formFieldTypes"
              size="sm"
            ></b-form-select>
          </div>
        </div>
        <div v-if="formField.type === 'dropDownList'">
          <drop-down-list-type
            :optionArray.sync="formField.options"
          ></drop-down-list-type>
        </div>
        <div class="px-5" v-if="formField.type === 'rating'">
          <div class="row headers">
            <div class="col-sm-3">
              <label>Scale</label>
            </div>
            <div class="col-sm-7">
              <label>Text</label>
            </div>
            <div class="col-sm-2">
              <label>Visible</label>
            </div>
          </div>
          <div
            class="row py-1"
            v-for="(scale, index) in formField.scales"
            :key="scale.name"
          >
            <div class="col-sm-3">
              <label>{{ scale.name }}</label>
            </div>
            <div class="col-sm-7">
              <b-input
                maxlength="30"
                class="form-control-sm"
                v-model.trim="scale.text"
                @blur="$sanitize('formField.scales.' + index + '.text')"
                required
              ></b-input>
            </div>
            <div class="col-sm-2" style="text-align: center">
              <b-form-checkbox v-model="scale.isVisible" />
            </div>
          </div>
        </div>
      </b-form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="hideModal">Cancel</b-button>
        <b-button variant="primary" @click="addFormField">
          {{ isEdit ? 'Edit' : 'Add' }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import DropDownListType from './AnswerFormDropDownList';

export default {
  /*global _*/
  props: ['formFields'],
  mounted() {
    this.tableItems = this.formFields;
  },
  components: {
    DropDownListType,
  },
  data() {
    return {
      tableHeaders: [
        {
          key: 'name',
          label: 'Field Name',
        },
        {
          key: 'isRequired',
          label: 'Required?',
        },
        {
          key: 'type',
          label: 'Field Type',
        },
        {
          key: 'action',
          label: '',
        },
      ],
      tableItems: [],
      formFieldTypes: [
        { value: 'text', text: 'Text' },
        { value: 'textArea', text: 'Text Area' },
        { value: 'dropDownList', text: 'Drop Down List' },
        { value: 'imageUpload', text: 'Image Upload' },
      ],
      isEdit: false,
      editId: -1,
      formField: {
        name: '',
        isRequired: true,
        type: 'text',
        options: [''],
        scales: [],
      },
      formFieldDefault: {
        name: '',
        isRequired: true,
        type: 'text',
        compulsory: false,
      },
      options: [''],
      scalesDefault: [
        { name: 'Scale 5', text: 'Excellent', isVisible: true, value: 5 },
        { name: 'Scale 4', text: 'Very Good', isVisible: true, value: 4 },
        { name: 'Scale 3', text: 'Good', isVisible: true, value: 3 },
        { name: 'Scale 2', text: 'Fair', isVisible: true, value: 2 },
        { name: 'Scale 1', text: 'Poor', isVisible: true, value: 1 },
      ],
    };
  },
  watch: {
    formFields: function (newVal) {
      if (newVal) {
        this.tableItems = newVal;
      }
    },
  },
  methods: {
    defaultScales() {
      return [
        { name: 'Scale 5', text: 'Excellent', isVisible: true, value: 5 },
        { name: 'Scale 4', text: 'Very Good', isVisible: true, value: 4 },
        { name: 'Scale 3', text: 'Good', isVisible: true, value: 3 },
        { name: 'Scale 2', text: 'Fair', isVisible: true, value: 2 },
        { name: 'Scale 1', text: 'Poor', isVisible: true, value: 1 },
      ];
    },
    showModal() {
      this.isEdit = false;
      this.editId = -1;
      this.formField = Object.assign({}, this.formFieldDefault);
      this.formField.options = [''];
      this.$refs['field-modal'].show();
    },
    showModalForEdit(data) {
      this.isEdit = true;
      this.editId = data.index;
      this.formField = _.cloneDeep(
        _.assign({}, this.formFieldDefault, data.item)
      );
      if (this.formField.type === 'rating') {
        this.formField.scales = _.assign(
          {},
          this.defaultScales(),
          data.item.scales
        );
      }
      // eslint-disable-next-line no-console
      console.log(this.formField);
      this.$refs['field-modal'].show();
    },
    hideModal() {
      this.$refs['field-modal'].hide();
    },
    addFormField() {
      //Check for duplicate field
      const list = this.tableItems;
      const editId = this.editId;
      if (
        _.some(
          _.filter(this.tableItems, function (a) {
            return list.indexOf(a) !== editId;
          }),
          _.pick(this.formField, ['name'])
        )
      ) {
        this.$bvToast.toast('Duplicate field name is not allowed', {
          title: ``,
          variant: 'warning',
          solid: true,
        });
        return;
      }

      //Check validity
      const fieldForm = this.$refs['field-form'];
      if (!fieldForm.reportValidity()) {
        return;
      }

      //Edit
      if (this.isEdit) {
        this.$set(
          this.tableItems,
          this.editId,
          Object.assign({}, this.formField)
        );
      }
      //Create
      else {
        this.tableItems.push(Object.assign({}, this.formField));
      }
      this.$emit('update:formFields', this.tableItems);
      this.$refs['field-modal'].hide();
    },
    removeFormField(item) {
      this.tableItems = _.without(this.tableItems, item);
      this.$emit('update:formFields', this.tableItems);
    },
    addOption() {
      this.formField.options.push('');
    },
    removeOption(option) {
      this.options = _.filter(this.options, (a) => {
        return a !== option;
      });
      if (this.options.length < 1) {
        this.options.push('');
      }
    },
    getTypeName(val) {
      switch (val) {
        case 'text':
          return 'Text';
        case 'textArea':
          return 'Text Area';
        case 'dropDownList':
          return 'Drop Down List';
        case 'datePicker':
          return 'DatePicker';
        case 'rating':
          return 'Rating';
        case 'imageUpload':
          return 'Image Upload';
        default:
          return val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-inline-text {
  font-size: 0.9rem;

  &:hover {
    cursor: pointer;
    font-weight: bolder;
  }
}

.headers {
  font-weight: bold;
  text-align: center;
}
</style>
